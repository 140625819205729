import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });

// The AG-Grid License
// Also added in session.service.spec.ts for unit tests
LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'CompanyName=App Dynamic ehf.,LicensedApplication=AGR5,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=73,AssetReference=AG-021201,ExpiryDate=28_November_2022_[v2]_MTY2OTU5MzYwMDAwMA==93994d2c45951e8aa0f08f86dca9cb3b'
);
