import { Feature, FeatureDTO } from 'src/app/_core/models/feature.model';
import { TimezoneService } from 'src/app/_core/timezone.service';

export interface RoleDTO {
  id: number;
  name: string;
  caption: string;
  createdAt: string; // Date
  updatedAt: string; // Date
  features: FeatureDTO[];
}

export class Role {
  id: number;
  name: string;
  caption: string;
  createdAt: Date;
  updatedAt: Date;
  features: Feature[] = [];

  constructor(dto: RoleDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.caption = dto.caption;
    this.createdAt = TimezoneService.serverTimeToClientTime(dto.createdAt);
    this.updatedAt = TimezoneService.serverTimeToClientTime(dto.updatedAt);
    this.features = dto.features ? dto.features.map((featureDto) => new Feature(featureDto)) : this.features;
  }

  get captionAndRoleId(): string {
    return `${this.caption} (${this.name})`;
  }

  toDto(): RoleDTO {
    return {
      id: this.id,
      name: this.name,
      caption: this.caption,
      createdAt: TimezoneService.dateToISOString(this.createdAt),
      updatedAt: TimezoneService.dateToISOString(this.updatedAt),
      features: this.features.map((feature) => feature.toDto())
    };
  }
}
