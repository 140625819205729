import { Component, Input } from '@angular/core';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';

@Component({
  selector: '[agr-details-non-editable-columns]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './non-editable-columns.component.html',
  styleUrls: ['../details-columns.component.scss']
})
export class NonEditableColumnsComponent {
  @Input() column: DetailsColumn;
  @Input() itemId: number;

  getValue(): number {
    return this.column.overwriteValue ?? this.column.value;
  }
}
