import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToasterService } from 'angular2-toaster';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { TranslationsService } from 'src/app/_core/translations.service';
import { ChartDataParams } from 'src/app/item-card/models/chart-data-params.model';
import {
  ItemCardDTO,
  ItemOrderRouteDTO,
  SerieDataUpdateDTO,
  SerieDataUpdateResponseDTO
} from 'src/app/item-card/models/item-card-dto.model';
import { ItemDetails } from 'src/app/item-card/models/item-details.model';
import { ItemLabel, ItemLabelDTO } from 'src/app/item-card/models/item-label.model';
import { ItemOrderRoute } from 'src/app/item-card/models/item-order-route.model';
import { ItemSimple, ItemSimpleDTO } from 'src/app/item-card/models/item-simple.model';
import { ItemUndeliveredOrder, ItemUndeliveredOrderDTO } from 'src/app/item-card/models/item-undelivered-order.model';

@Injectable({
  providedIn: 'root'
})
export class ItemCardService {
  constructor(private http: HttpClient, private toasterService: ToasterService) {}

  getItemDetails(itemId: number): Observable<ItemDetails> {
    return this.getItemCardDto(itemId).pipe(map((itemCardDTO: ItemCardDTO) => new ItemDetails(itemCardDTO.item)));
  }

  getSimpleItem(itemId: number): Observable<ItemSimple> {
    return this.http
      .get<ItemSimpleDTO>(`${API_ROOT}/items/${itemId}`)
      .pipe(map((itemSimpleDTO: ItemSimpleDTO) => new ItemSimple(itemSimpleDTO)));
  }

  getUndeliveredOrders(itemId: number): Observable<ItemUndeliveredOrder[]> {
    return this.http.get<ItemUndeliveredOrderDTO[]>(`${API_ROOT}/items/${itemId}/stock-details`).pipe(
      map((orderDTOs: ItemUndeliveredOrderDTO[]) => {
        return orderDTOs
          .map((order) => new ItemUndeliveredOrder(order))
          .sort((a, b) => a.estimatedDeliveryDate.getTime() - b.estimatedDeliveryDate.getTime());
      })
    );
  }

  getItemOrderRoutes(itemId: number): Observable<ItemOrderRoute[]> {
    return this.getItemCardDto(itemId).pipe(
      map((itemDTO: ItemCardDTO) => {
        return itemDTO.item.itemOrderRoutes.map((itemOrderRouteDTO: ItemOrderRouteDTO) => {
          return new ItemOrderRoute(itemOrderRouteDTO);
        });
      })
    );
  }

  getItemLabelList(itemId: number): Observable<ItemLabel[]> {
    return this.http.get<ItemLabel[]>(`${API_ROOT}/items/${itemId}/label-list`).pipe(
      map((labelDTOs: ItemLabelDTO[]) => {
        return labelDTOs.map((label) => new ItemLabel(label));
      })
    );
  }

  recalculatePurchasePlan(itemId: number, params: ChartDataParams): Observable<void> {
    const url = `${API_ROOT}/items/${itemId}/demand-recalculate?periodType=${params.periodType}&forecastLength=${params.forecastLength}`;
    return this.http.put<void>(url, {}).pipe(
      map(() => {
        this.toasterService.pop('info', TranslationsService.get('PURCHASE_PLAN_RECALCULATED'));
      })
    );
  }

  updateSerieData(
    itemId: number,
    params: ChartDataParams,
    chartElementId: number,
    serieDataUpdate: SerieDataUpdateDTO
  ): Observable<SerieDataUpdateResponseDTO> {
    const url = `${API_ROOT}/items/${itemId}`;
    const chartUrl = `/chart-data/${params.periodType}/${chartElementId}`;
    return this.http.put<SerieDataUpdateResponseDTO>(`${url}${chartUrl}`, serieDataUpdate);
  }

  updateForecastSettings(item: ItemDetails): Observable<{}> {
    if (isNaN(item.id)) {
      return EMPTY;
    }
    const url = `${API_ROOT}/items/${item.id}/forecast-settings`;
    return this.http.put(url, item.toUpdateForecastSettingsDto());
  }

  /**
   * This endpoint is *incomplete* and should not be used directly by component classes
   * This function should always be used if using the endpoint /api/items/${itemID}/chart_data/${periodType}?...
   */
  private getItemCardDto(itemId: number, chartParams?: ChartDataParams, orderId?: number): Observable<ItemCardDTO> {
    const url = `${API_ROOT}/items/${itemId}`;
    const params = chartParams ? chartParams : { periodType: 'day', historyLength: 0, forecastLength: 0 };
    let chartUrl = `/item-card-data/${params.periodType}?forecastLength=${params.forecastLength}&historyLength=${params.historyLength}`;
    chartUrl = orderId ? `${chartUrl}&orderId=${orderId}` : chartUrl;
    return this.http.get<ItemCardDTO>(`${url}${chartUrl}`).pipe(
      map((itemDTO: ItemCardDTO) => {
        itemDTO.chartOptions = JSON.parse(itemDTO.chartOptions as unknown as string);
        itemDTO.chartElementTypes.map((elementType) => {
          elementType.highchartsSeriesOptions = JSON.parse(elementType.highchartsSeriesOptions as unknown as string);
          elementType.highchartsSeriesOptions.name = TranslationsService.get(elementType.highchartsSeriesOptions.name.toUpperCase());
        });
        itemDTO.chartData.forEach((data) => (data.chart_date = new Date(data.chart_date).valueOf()));
        return itemDTO;
      })
    );
  }
}
