<div class="column-label d-flex justify-content-between">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #nonEditableColumnDescription>
    <h6>{{ column.caption | agrTranslate }}</h6>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button type="button" class="btn btn-help" [ngbPopover]="nonEditableColumnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
</div>

<div class="column-value">
  <span>
    <span *ngIf="column.type === 'currency' || column.type === 'decimal'" class="me-2">
      {{ column.overwriteValue ?? column.value | agrFormat: column.type + ':2' }}
    </span>
    <span *ngIf="column.type !== 'currency' && column.type !== 'decimal'" class="me-2">
      {{ column.overwriteValue ?? column.value | agrFormat: column.type }}
    </span>
  </span>
</div>
