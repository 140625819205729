import { AccessGroup, AccessGroupDTO } from 'src/app/_core/models/access-group.model';
import { FeatureDTO } from 'src/app/_core/models/feature.model';
import { Role, RoleDTO } from 'src/app/_core/models/role.model';
import { TimezoneService } from 'src/app/_core/timezone.service';

/**
 * User interface is based on the UserDTO from API.
 * All properties need to be optional because the user can be in so many states:
 * E.g. login user data, logged in user, create user, my profile user.
 */
export interface UserDTO {
  id?: number; // optional for create user
  userId?: number; // Used for my profile
  username?: string; // optional for my profile
  email?: string;
  name?: string;
  auth365?: boolean;
  language?: string;
  locale?: string;
  created_at?: string; // Date
  roles?: RoleDTO[];
  accessGroups?: AccessGroupDTO[];
  system?: boolean; // if system user
  currentPassword?: string;
  password?: string; // new? password
  passwordConfirmation?: string;
  editingPassword?: boolean; // Used for my profile
  deleted?: boolean;

  featuresStringList?: string[];
  features?: FeatureDTO[];
}

export interface UserBasicInfoDTO {
  id: number;
  auth365: boolean;
  username: string;
  name: string;
  email: string;
  language: string;
}

export interface UserUpdateResponse {
  user: UserDTO;
  Success: boolean;
  error: any;
  Password: string;
}

export class User {
  id?: number;
  username?: string;
  email?: string;
  name?: string;
  auth365?: boolean;
  language?: string;
  locale?: string;
  createdAt?: Date;
  roles?: Role[];
  accessGroups?: AccessGroup[];
  system?: boolean;
  currentPassword?: string;
  password?: string;
  passwordConfirmation?: string;
  deleted?: boolean;

  constructor(user?: UserDTO) {
    if (!user) {
      return;
    }
    this.id = user.id;
    this.username = user.username;
    this.email = user.email;
    this.name = user.name;
    this.auth365 = user.auth365;
    this.language = user.language;
    this.locale = user.locale;
    this.createdAt = TimezoneService.serverTimeToClientTime(user.created_at);
    this.roles = Array.isArray(user.roles) ? user.roles.map((role) => new Role(role)) : [];
    this.accessGroups = (user.accessGroups || []).map((dto) => new AccessGroup(dto));
    this.system = user.system;
    this.deleted = user.deleted;
  }

  get nameAndUserId(): string {
    return `${this.name} (${this.username})`;
  }

  toDto(): UserDTO {
    return {
      id: this.id,
      username: this.username,
      email: this.email,
      name: this.name,
      auth365: this.auth365,
      language: this.language,
      locale: this.locale,
      created_at: TimezoneService.dateToISOString(this.createdAt),
      roles: this.roles.map((role) => role.toDto()),
      accessGroups: this.accessGroups.map((group) => group.toDto()),
      system: this.system,
      currentPassword: this.currentPassword,
      password: this.password,
      passwordConfirmation: this.passwordConfirmation,
      deleted: this.deleted
    };
  }
}
